import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
import VueSuperSlide from 'vue-superslide'
Vue.use(VueSuperSlide)

// 引入全局css
import './assets/scss/style.scss';


//引入wow
import 'wowjs/css/libs/animate.css'
import {WOW} from 'wowjs';
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default  需要执行动画的元素的 class
  animateClass: 'animated', // default  animation.css 动画的 class
  offset: 0, // default  距离可视区域多少开始执行动画
  mobile: true, // default  是否在移动设备上执行动画
  live: false,  // 异步加载的内容是否有效
})


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
