import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'load',
  component: () => import('../views/load.vue')
},{
  path: '/index',
  name: 'index',
  component: () => import('../views/index.vue')
},
{
  path: '/announce',
  name: 'announce',
  component: () => import('@/views/announce')
},
{//首页
  path: '/home',
  name: 'home',
  component: () => import('../views/home.vue')
},
{//产品中心
  path: '/product',
  name: 'product',
  component: () => import('@/views/product')
},
{//解决方案
  path: '/solution',
  name: 'solution',
  component: () => import('@/views/solution')
},
{//解决方案-详情
  path: '/solutionDetail',
  name: 'solutionDetail',
  component: () => import('@/views/solution/detail')
},
{//新闻资讯
  path: '/news',
  name: 'news',
  component: () => import('@/views/news')
},
{//新闻资讯-详情
  path: '/newsDetail',
  name: 'newsDetail',
  component: () => import('@/views/news/detail')
},
{//关于我们
  path: '/about',
  name: 'about',
  component: () => import('@/views/about')
},
{//联系我们
  path: '/contact',
  name: 'contact',
  component: () => import('@/views/contact')
},
{//网站地图
  path: '/WebsiteMap',
  name: 'WebsiteMap',
  component: () => import('@/views/WebsiteMap')
},
{//网站建设
  path: '/web',
  name: 'web',
  component: () => import('@/views/core/web')
},
{//APP
  path: '/app',
  name: 'app',
  component: () => import('@/views/core/app')
},
{//物联网
  path: '/wlw',
  name: 'wlw',
  component: () => import('@/views/core/wlw')
},
{//小程序
  path: '/xcx',
  name: 'xcx',
  component: () => import('@/views/core/xcx')
},
{//小程序
  path: '/zzdetails',
  name: 'zzdetails',
  component: () => import('@/views/zzdetails')
},



{ path: '*', redirect: '/home', hidden: true }
]
const router = new VueRouter({
  mode: "history",
  routes
})

export default router